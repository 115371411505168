import STORES from "constants/stores";

const getStores = (type, location, promoted) => {
    return STORES.filter(store => {
        let result = store.types.includes(type);

        if (result && location) {
            result = store.location === location;
        }

        if (result && promoted) {
            result = store.promoted === true;
        }

        return result;
    });
};

export function uniqueStores(stores) {
    return stores.filter(
        (store, index, self) => self.findIndex(s => s.id === store.id) === index
    );
}

export const resellers = uniqueStores(getStores("reseller", null, true));
export const merchants = getStores("merchant", null, true);

export const physicalStores = uniqueStores([
    ...getStores("merchant", "physical"),
    ...getStores("reseller", "physical")
]);

export const onlineStores = uniqueStores([
    ...getStores("merchant", "online"),
    ...getStores("reseller", "online")
]);
