import PropTypes from "prop-types";
import colors from "constants/colors";
import Link from "next/link";
import IconChevronRight from "components/shared/svg/IconChevronRight";

import { BreadcrumbStyled, IconContainer } from "./Breadcrumb.styled";

function Breadcrumb({ item, isLast }) {
    return (
        <BreadcrumbStyled>
            {!isLast && (
                <>
                    <Link href={item?.url} passHref prefetch={false}>
                        <a>{item?.title}</a>
                    </Link>
                    <IconContainer>
                        <IconChevronRight fill={colors.white} />
                    </IconContainer>
                </>
            )}
            {isLast && item.title}
        </BreadcrumbStyled>
    );
}

Breadcrumb.propTypes = {
    item: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string,
        isLast: PropTypes.bool
    }).isRequired,
    isLast: PropTypes.bool
};

Breadcrumb.defaultProps = {
    isLast: false
};

export default Breadcrumb;
