import styled, { css } from "styled-components";
import media from "constants/media";

export const UspTickerStyled = styled.div`
    display: flex;
    align-items: center;
`;

export const Icon = styled.img`
    height: 32px;
`;

export const Content = styled.div`
    height: 25px;
    overflow: hidden;

    ${({ type }) =>
        type !== "smaller" &&
        css`
            margin-left: -10px;
        `}
`;

export const Text = styled.p`
    color: var(
        --color-${({ type }) => (type === "inverted" ? "white" : "darkNight")}
    );
    font-weight: ${({ type }) => (type === "smaller" ? "normal" : 500)};
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    text-align: left;

    ${media.mdUp`
        font-size: 16px;
        line-height: 25px;
    `}

    ${({ type }) =>
        type === "smaller" &&
        css`
            svg {
                margin-right: 12px;
            }
        `}
`;

export const Current = styled(Text)`
    margin-top: ${({ tickerChange }) => (tickerChange ? "-25px" : "0")};

    transition: ${({ tickerChange }) =>
        tickerChange ? "0.4s all ease-in-out" : "none"};

    ${({ type }) =>
        type !== "smaller" &&
        css`
            width: 312px;
        `}
`;
