import styled from "styled-components";

export const BreadcrumbsStyled = styled.div`
    display: flex;
    width: 100%;
`;

export const List = styled.ol`
    display: flex;
    align-items: center;
    width: 100%;

    list-style: none;
`;
