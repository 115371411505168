import styled from "styled-components";
import media from "constants/media";
import PropTypes from "prop-types";

const Paragraph = styled.p`
    margin-bottom: 25px;

    font-size: 18px;
    line-height: 25px;
    text-align: center;

    ${media.mdUp`
        margin-bottom: 30px;

        font-size: 20px;
        line-height: 30px;
        text-align: ${({ align }) => align ?? "center"};
    `}

    strong {
        font-weight: 500;
    }
`;

function Description({ children, className, ...props }) {
    return (
        <Paragraph className={className} {...props}>
            {children}
        </Paragraph>
    );
}

Description.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

Description.defaultProps = {
    className: ""
};

export default Description;
