/* eslint-disable react/no-danger */
import PropTypes from "prop-types";

import { BreadcrumbsStyled, List } from "./Breadcrumbs.styled";
import Breadcrumb from "./breadcrumb/Breadcrumb";

function Breadcrumbs({ items, hideMobile }) {
    const breadcrumbs = [
        {
            title: "Home",
            url: "/"
        },
        ...items
    ];

    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: breadcrumbs.map((item, index) => {
            return {
                "@type": "ListItem",
                position: index + 1,
                name: item.title,
                item: process.env.NEXT_PUBLIC_BASE_URL + item.url
            };
        })
    };

    return (
        <BreadcrumbsStyled hideMobile={hideMobile}>
            <List>
                {breadcrumbs?.map((item, index) => {
                    return (
                        <Breadcrumb
                            key={JSON.stringify(item)}
                            item={item}
                            isLast={breadcrumbs.length === index + 1}
                        />
                    );
                })}
            </List>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
            />
        </BreadcrumbsStyled>
    );
}

Breadcrumbs.defaultProps = {
    items: [],
    hideMobile: true
};

Breadcrumbs.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string
        }).isRequired
    ),
    hideMobile: PropTypes.bool
};

export default Breadcrumbs;
