import PropTypes from "prop-types";
import colors from "constants/colors";

function IconChevronRight({ fill }) {
    return (
        <svg viewBox="0 0 64 64">
            <g fill={fill} fillRule="evenodd">
                <path d="M24.793 52.622L22 50.369l15.405-18.558L22 13.252 24.793 11 42 31.81z" />
            </g>
        </svg>
    );
}

IconChevronRight.propTypes = {
    fill: PropTypes.string
};

IconChevronRight.defaultProps = {
    fill: colors.darkNight
};

export default IconChevronRight;
