import { merchants, resellers, uniqueStores } from "helpers/stores";
import PropTypes from "prop-types";

import {
    LogoContainer,
    LogoImage,
    Logos,
    Wrapper,
    Partial,
    PartialTitle,
    PartialContent
} from "./StoresLogos.styled";

export default function StoresLogos({ isRedeem, isHomepage, className }) {
    const stores = isRedeem || isHomepage ? merchants : resellers;

    const physicalStores = stores.filter(
        store => store.location === "physical"
    );

    // Filter out online stores that are not in the physicalStores array
    const onlineStores = stores.filter(store => store.location === "online");

    return (
        <Logos className={className || null}>
            {isRedeem || isHomepage ? (
                <Wrapper>
                    {physicalStores && physicalStores.length > 0 && (
                        <Partial>
                            <PartialTitle isBigger={isHomepage}>
                                In de winkel in te wisselen bij:
                            </PartialTitle>
                            <PartialContent isBigger={isHomepage}>
                                {physicalStores.map(brand => {
                                    const sizes =
                                        brand?.image?.sizes?.small ??
                                        brand?.image?.sizes?.default;

                                    return (
                                        <LogoContainer key={brand.id}>
                                            <LogoImage
                                                src={brand?.image?.color}
                                                alt={`${brand.id} logo`}
                                                width={sizes.width}
                                                height={sizes.height}
                                                loading="lazy"
                                            />
                                        </LogoContainer>
                                    );
                                }) ?? []}
                            </PartialContent>
                        </Partial>
                    )}

                    {onlineStores && onlineStores.length > 0 && (
                        <Partial>
                            <PartialTitle isBigger={isHomepage}>
                                Online in te wisselen bij:
                            </PartialTitle>
                            <PartialContent
                                isSmaller={!isHomepage}
                                isBigger={isHomepage}
                            >
                                {onlineStores?.map(brand => {
                                    const sizes =
                                        brand?.image?.sizes?.small ??
                                        brand?.image?.sizes?.default;

                                    return (
                                        <LogoContainer key={brand.id}>
                                            <a
                                                href={brand.href}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <LogoImage
                                                    src={brand?.image?.color}
                                                    alt={`${brand.id} logo`}
                                                    width={sizes.width}
                                                    height={sizes.height}
                                                    loading="lazy"
                                                />
                                            </a>
                                        </LogoContainer>
                                    );
                                }) ?? []}
                            </PartialContent>
                        </Partial>
                    )}
                </Wrapper>
            ) : (
                uniqueStores(stores)?.map(brand => {
                    const sizes =
                        brand?.image?.sizes?.small ??
                        brand?.image?.sizes?.default;

                    return (
                        <LogoContainer key={brand.id}>
                            <LogoImage
                                src={brand?.image?.color}
                                alt={`${brand.id} logo`}
                                width={sizes.width}
                                height={sizes.height}
                                loading="lazy"
                            />
                        </LogoContainer>
                    );
                }) ?? []
            )}
        </Logos>
    );
}

StoresLogos.defaultProps = {
    className: undefined,
    isRedeem: false,
    isHomepage: false
};

StoresLogos.propTypes = {
    className: PropTypes.string,
    isRedeem: PropTypes.bool,
    isHomepage: PropTypes.bool
};
