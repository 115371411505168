import styled, { css } from "styled-components";
import media from "constants/media";

export const Logos = styled.div`
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    justify-content: space-around;

    list-style: none;
`;

export const LogoContainer = styled.li`
    width: 33.3%;
    margin: 10px 0;

    text-align: center;
`;

export const LogoImage = styled.img`
    width: 75%;
    max-width: 75px;
    max-height: 15px;

    ${media.mdUp`
        max-width: 110px;
        max-height: 25px;
    `}
`;

export const Wrapper = styled.div`
    width: 100%;

    ${media.mdUp`
        display: flex;
        justify-content: space-between;
    `}
`;
export const Partial = styled.div`
    width: 100%;
    margin-bottom: 20px;

    ${media.mdUp`
        width: 45%;
    `}
`;

export const PartialTitle = styled.h3`
    width: 100%;
    margin-bottom: 10px;

    font-weight: ${({ isBigger }) => (isBigger ? "normal" : "bold")};
    font-size: 14px;
    text-align: ${({ isBigger }) => (isBigger ? "center" : "left")};

    ${media.mdUp`
        margin-bottom: ${({ isBigger }) => (isBigger ? "10px" : "20px")};
        text-align: center;
        font-size: 15px;
    `}
`;

export const PartialContent = styled.ul`
    display: flex;
    margin: 0;
    padding: 0;

    ${({ isBigger, isSmaller }) =>
        (isBigger || !isSmaller) &&
        css`
            justify-content: center;
            align-items: center;
        `}

    list-style: none;

    > * {
        width: 25%;
    }

    ${media.mdUp`
        flex-wrap: ${({ isBigger }) => (isBigger ? "nowrap" : "wrap")};

        > * {
            width: ${({ isSmaller }) => (isSmaller ? `100%` : "50%")}
        }
    `}
`;
