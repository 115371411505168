import styled from "styled-components";
import media from "constants/media";
import PropTypes from "prop-types";

const H1 = styled.h1`
    margin-bottom: 15px;

    font-weight: normal;
    font-size: 35px;
    font-family: var(--font-Agne);
    line-height: 40px;
    letter-spacing: 1px;
    text-align: center;

    span {
        font-family: var(--font-DINPro);
    }

    ${media.mdUp`
        margin-bottom: 20px;
        
        font-size: 64px;
        line-height: 70px;
        text-align: ${({ align }) => align ?? "center"};
    `};
`;

function Title({ children, className, ...props }) {
    return (
        <H1 className={className} {...props}>
            {children}
        </H1>
    );
}

Title.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

Title.defaultProps = {
    className: ""
};

export default Title;
