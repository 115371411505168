import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import colors from "constants/colors";

import IconCheck from "components/shared/svg/IconCheck";
import {
    UspTickerStyled,
    Icon,
    Content,
    Current,
    Text
} from "./UspTicker.styled";

const defaultUsps = [
    "Boeken, e-books & tijdschriften",
    "Kies je eigen verpakking",
    "Op werkdagen voor 17:00 besteld = vandaag verstuurd"
];

function UspTicker({ usps, type }) {
    const [currentId, setCurrentId] = useState(0);
    const [tickerChange, setTickerChange] = useState(false);

    const renderedUsps = usps.length > 0 ? usps : defaultUsps;

    useEffect(() => {
        let tickerInterval;
        let delayTimeout;

        if (renderedUsps.length > 1) {
            tickerInterval = setInterval(() => {
                setTickerChange(true);
                delayTimeout = setTimeout(() => {
                    setCurrentId(c =>
                        c < renderedUsps.length - 1 ? c + 1 : 0
                    );
                    setTickerChange(false);
                }, 400);
            }, 3000);
        } else {
            if (tickerInterval) {
                clearInterval(tickerInterval);
            }

            if (delayTimeout) {
                clearTimeout(delayTimeout);
            }
        }

        return () => {
            clearInterval(tickerInterval);
            clearTimeout(delayTimeout);
        };
    }, [renderedUsps]);

    if (!renderedUsps?.length > 0) {
        return null;
    }

    return (
        <UspTickerStyled>
            {type !== "smaller" && (
                <Icon
                    src="/images/icon-check-neon.svg"
                    alt="check icon"
                    loading="lazy"
                    width={42}
                    height={32}
                />
            )}
            <Content type={type}>
                <Current tickerChange={tickerChange} type={type}>
                    {type === "smaller" && <IconCheck fill={colors.peach} />}
                    <span
                        dangerouslySetInnerHTML={{ __html: usps[currentId] }} //eslint-disable-line
                    />
                </Current>
                {renderedUsps && renderedUsps.length > 1 && (
                    <Text type={type}>
                        {type === "smaller" && (
                            <IconCheck fill={colors.peach} />
                        )}
                        <span
                            // eslint-disable-next-line
                            dangerouslySetInnerHTML={{
                                __html:
                                    currentId > renderedUsps.length - 2
                                        ? renderedUsps[0]
                                        : renderedUsps[currentId + 1]
                            }}
                        />
                    </Text>
                )}
            </Content>
        </UspTickerStyled>
    );
}

UspTicker.propTypes = {
    usps: PropTypes.array,
    type: PropTypes.oneOf(["default", "inverted", "smaller"])
};

UspTicker.defaultProps = {
    usps: defaultUsps,
    type: "default"
};

export default UspTicker;
