import styled from "styled-components";

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 3px;
    padding-left: 5px;

    svg {
        width: 12px;
        height: 12px;
    }
`;

export const BreadcrumbStyled = styled.li`
    display: flex;
    align-items: center;

    padding-right: 5px;

    color: var(--color-white);

    font-size: 12px;
    line-height: 25px;

    a {
        color: var(--color-peach);
    }
`;
