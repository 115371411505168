const STORES = [
    {
        id: "Primera",
        image: {
            color: "/images/logo-primera-color.svg",
            white: "/images/logo-primera-white.svg",
            sizes: {
                default: {
                    width: 86,
                    height: 16
                }
            }
        },
        types: ["reseller", "merchant"],
        promoted: true,
        location: "physical"
    },
    {
        id: "Jumbo",
        image: {
            color: "/images/logo-jumbo-color.svg",
            white: "/images/logo-jumbo-white.svg",
            sizes: {
                default: {
                    width: 86,
                    height: 22
                }
            }
        },
        href: "",
        types: ["reseller"],
        promoted: true,
        location: "physical"
    },
    {
        id: "Bruna",
        image: {
            color: "/images/logo-bruna-new-color.svg",
            white: "/images/logo-bruna-new-white.svg",
            sizes: {
                default: {
                    width: 86,
                    height: 24
                }
            }
        },
        types: ["reseller", "merchant"],
        promoted: true,
        location: "physical"
    },
    {
        id: "Bruna",
        image: {
            color: "/images/logo-bruna-new-color.svg",
            white: "/images/logo-bruna-new-white.svg",
            sizes: {
                default: {
                    width: 86,
                    height: 24
                }
            }
        },
        types: ["reseller", "merchant"],
        promoted: true,
        location: "online"
    },
    {
        id: "The Read Shop",
        image: {
            color: "/images/logo-thereadshop-color.svg",
            white: "/images/logo-thereadshop-white.svg",
            sizes: {
                default: {
                    width: 86,
                    height: 22
                }
            }
        },
        types: ["reseller", "merchant"],
        promoted: true,
        location: "physical"
    },
    {
        id: "Boni",
        image: {
            color: "/images/logo-boni-color.svg",
            white: "",
            sizes: {
                default: {
                    width: 86,
                    height: 22
                }
            }
        },
        href: "",
        types: ["reseller"],
        promoted: false,
        location: "physical"
    },
    {
        id: "Boon's Markt",
        image: {
            color: "/images/logo-boon-color.svg",
            white: "",
            sizes: {
                default: {
                    width: 86,
                    height: 22
                }
            }
        },
        href: "",
        types: ["reseller"],
        promoted: false,
        location: "physical"
    },
    {
        id: "Hoogvliet",
        image: {
            color: "/images/logo-hoogvliet-color.svg",
            white: "",
            sizes: {
                default: {
                    width: 86,
                    height: 22
                }
            }
        },
        href: "",
        types: ["reseller"],
        promoted: false,
        location: "physical"
    },
    {
        id: "Dirk",
        image: {
            color: "/images/logo-dirk-color.svg",
            white: "",
            sizes: {
                default: {
                    width: 86,
                    height: 22
                }
            }
        },
        href: "",
        types: ["reseller"],
        promoted: false,
        location: "physical"
    },
    {
        id: "DekaMarkt",
        image: {
            color: "/images/logo-dekamarkt-color.svg",
            white: "",
            sizes: {
                default: {
                    width: 86,
                    height: 22
                }
            }
        },
        href: "",
        types: ["reseller"],
        promoted: false,
        location: "physical"
    },
    {
        id: "Jan Linders",
        image: {
            color: "/images/logo-janlinders-color.svg",
            white: "",
            width: 86,
            height: 24
        },
        href: "",
        types: ["reseller"],
        promoted: false,
        location: "physical"
    },
    {
        id: "Poiesz",
        image: {
            color: "/images/logo-poiesz-color.svg",
            white: "",
            sizes: {
                default: {
                    width: 86,
                    height: 22
                }
            }
        },
        href: "",
        types: ["reseller"],
        promoted: false,
        location: "physical"
    },
    {
        id: "Coop",
        image: {
            color: "/images/logo-coop-color.svg",
            white: "",
            sizes: {
                default: {
                    width: 172,
                    height: 44
                }
            }
        },
        href: "",
        types: ["reseller"],
        promoted: false,
        location: "physical"
    },
    {
        id: "Plus",
        image: {
            color: "/images/logo-plus-color.svg",
            white: "",
            sizes: {
                default: {
                    width: 86,
                    height: 22
                }
            }
        },
        href: "",
        types: ["reseller"],
        promoted: false,
        location: "physical"
    },
    {
        id: "Spar",
        image: {
            color: "/images/logo-spar-color.svg",
            white: "",
            sizes: {
                default: {
                    width: 86,
                    height: 22
                }
            }
        },
        href: "",
        types: ["reseller"],
        promoted: false,
        location: "physical"
    },
    {
        id: "Boekennl",
        image: {
            color: "/images/logo-boekennl-color.svg",
            white: "/images/logo-boekennl-white.svg",
            sizes: {
                default: {
                    width: 159,
                    height: 23
                },
                small: {
                    height: 86,
                    width: 12
                }
            }
        },
        href: "https://www.boeken.nl/",
        types: ["merchant"],
        promoted: true,
        location: "online"
    },
    {
        id: "Tijdschrif Caddeaukaart.nl",
        image: {
            color: "/images/logo-tijdschriftencadeaukaart-color.svg",
            white: "/images/logo-tijdschriftencadeaukaart-white.svg",
            sizes: {
                default: {
                    width: 203,
                    height: 66
                },
                small: {
                    height: 86,
                    width: 264
                }
            }
        },
        href: "https://www.tijdschriften-cadeaukaart.nl/",
        types: ["merchant"],
        promoted: true,
        location: "online"
    }
];

export default STORES;
